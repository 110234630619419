import APixList from './index'
import APixForm from './form'

export default [
  {
    path: '/pixes/',
    component: APixList,
    name: 'pixes',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/pixes/create',
    component: APixForm,
    name: 'pixes_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pixes/:pix/update',
    component: APixForm,
    name: 'pixes_update',
    meta: {
      requiresAuth: true
    }
  },
];
