<template>
  <v-app>
    <!--Navigation-->
    <v-navigation-drawer v-if="authCheck" v-model="drawer" clipped fixed app>
      <template v-slot:prepend>
        <v-list-item two-line v-if="business">
          <v-list-item-avatar rounded>
            <v-img :src="business.avatar ? business.avatar.url : require('./assets/placeholder.png')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ business.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ business.category ? business.category.name : 'Sem Categoria' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider/>

      <v-list nav dense>
        <v-list-item :to="{ name: 'dashboard' }">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'addresses' }">
          <v-list-item-icon>
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Endereços</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'telephones' }">
          <v-list-item-icon>
            <v-icon>mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Telefones</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
<!--        <v-list-item :to="{ name: 'transactions' }">-->
<!--          <v-list-item-icon>-->
<!--            <v-icon>mdi-swap-horizontal-bold</v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Transações</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--        <v-list-item :to="{ name: 'withdrawals' }">-->
<!--          <v-list-item-icon>-->
<!--            <v-icon>mdi-cash</v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Saques</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
        <v-list-item :to="{ name: 'subscriptions' }">
          <v-list-item-icon>
            <v-icon>mdi-shield-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Inscrições</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'cards' }">
          <v-list-item-icon>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cartões</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
<!--        <v-list-item :to="{ name: 'bank_accounts' }">-->
<!--          <v-list-item-icon>-->
<!--            <v-icon>mdi-bank</v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Contas de Banco</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--        <v-list-item :to="{ name: 'pixes' }">-->
<!--          <v-list-item-icon>-->
<!--            <v-icon>icon-pix</v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Chaves Pix</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
        <v-list-item :to="{ name: 'profile' }">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'settings' }">
          <v-list-item-icon>
            <v-icon>mdi-wrench</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configuração</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-row class="pa-3" dense>
          <v-col>
            <v-btn block depressed @click="logout">Sair</v-btn>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
    <!--App Bar-->
    <v-app-bar v-if="authCheck" dark app fixed clipped-left color="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title class="headline text-uppercase">
        <router-link :to="{ name: 'dashboard' }">
          <span class="white--text">POINT</span>
          <span class="white--text font-weight-light">URBANO</span>
          <span class="white--text body-2">{{ appName }}</span>
        </router-link>
      </v-toolbar-title>
    </v-app-bar>
    <!--Content-->
    <v-main :class="{ 'login-back': routesTheme.includes($route.name) }">
      <v-container fluid class="fill-height">
        <router-view/>
      </v-container>
    </v-main>
    <!--Loading Dialog-->
    <v-overlay :value="loadingDialog" opacity="0">
      <v-card color="primary"  class="pa-1" width="65">
        <v-img :src="require('./assets/loading.svg')" contain/>
      </v-card>
    </v-overlay>
  </v-app>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({
    routesTheme: ['login', 'register', 'reset_password']
  }),
  watch: {
    themeDark(value) {
      this.$vuetify.theme.dark = value;
    },
  },
  created() {
    this.$vuetify.theme.dark = this.themeDark;
    if (this.authCheck) {
      this.$store.dispatch('loginFetchAuthBusiness');
    }
  },
  methods: {
    logout() {
      if (confirm('Tem certeza que deseja sair?')) {
        this.$store.dispatch('loginRemoveSession')
          .finally(() => {
            this.$store.dispatch('setDrawerStatus', null);
            this.$router.push({name: 'login'});
          })
      }
    }
  },
  computed: {
    appName() {
      return this.$store.getters.getAppName;
    },
    themeDark() {
      return this.$store.getters.getThemeDark;
    },
    drawer: {
      get: function () {
        return this.$store.getters.getDrawerStatus;
      },
      set: function (status) {
        this.$store.dispatch('setDrawerStatus', status);
      },
    },
    loadingDialog: {
      get: function () {
        return this.$store.getters.getLoadingDialog;
      },
      set: function (status) {
        this.$store.dispatch('setLoadingDialogStatus', status);
      },
    },
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    },
    authCheck() {
      return this.$store.getters.getLoginAuthCheck;
    },
  }
}
</script>
