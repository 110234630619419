import Vue from 'vue';
import Vuex from 'vuex';

import address from "../views/Address/store.module";
import bankAccount from "../views/BankAccount/store.module";
import application from "../views/Application/store.module";
import card from "../views/Card/store.module";
import dashboard from "../views/Dashboard/store.module";
import httpNotFound from "../views/HttpNotFound/store.module";
import login from "../views/Login/store.module";
import pix from "../views/Pix/store.module";
import register from "../views/Register/store.module";
import profile from "../views/Profile/store.module";
import resetPassword from "../views/ResetPassword/store.module";
import settings from "../views/Settings/store.module";
import telephone from "../views/Telephone/store.module";
import transaction from "../views/Transaction/store.module";
import thirdPartyLogin from "../views/ThirdPartyLogin/store.module";
import withdrawal from "../views/Withdrawal/store.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    address,
    bankAccount,
    application,
    card,
    dashboard,
    httpNotFound,
    login,
    pix,
    register,
    profile,
    resetPassword,
    settings,
    telephone,
    transaction,
    thirdPartyLogin,
    withdrawal,
  },
})
