<template>
  <v-row dense v-if="payload" align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense>
              <v-col cols="12">
                <h1 class="text-subtitle-1 font-weight-light text-uppercase">Metodos de Pagamento</h1>
              </v-col>
              <v-col cols="12">
                <a-payment-method-select v-model="payload.payment_methods"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
          <v-btn depressed color="primary" @click="updateProfile()">Alterar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import APaymentMethodSelect from "@/components/APaymentMethodSelect";

export default {
  name: 'a-profile-about-update',
  components: {APaymentMethodSelect},
  data: () => ({
    payload: null,
    paymentMethodsSelected: [],
    valid: true,
  }),
  created() {
    this.$store.dispatch('fetchProfilePaymentMethods', {
      business: this.business.id
    }).then((response) => {
      this.payload = {
        payment_methods: response.data.data.map(item => item.id)
      }
    });
  },
  methods: {
    updateProfile() {
      if (!this.$refs.form.validate()) {
        alert('Verifique os campos do formulário!');
        return;
      }
      this.$store.dispatch('updateProfile', {
        business: this.business.id,
        payload: this.payload
      }).then((response) => {
        this.$refs.form.reset();
        this.$store.dispatch('loginSetBusiness', response.data.data);
        this.$router.back();
      });
    },
  },
  computed: {
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    },
  }
};
</script>
