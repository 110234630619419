<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Endereço</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'addresses_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="addresses">
      <v-col cols="12">
        <v-text-field v-model="addressesSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar"  @keyup.native.enter="searchAddresses()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
            :headers="addressesHeaders"
            :items="addresses.data"
            :page.sync="addresses.meta.current_page"
            :items-per-page.sync="addresses.meta.per_page"
            hide-default-footer
            disable-sort>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'addresses_update', params: { address: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyAddress(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="addresses.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-address-list',
    data: () => ({
      page: 1,
      addressesSearch: '',
      addressesHeaders: [
        { text: 'Endereço', value: 'address' },
        { text: 'CEP', value: 'cep' },
        { text: 'Número', value: 'number' },
        { text: 'Bairro', value: 'district' },
        { text: 'Complemento', value: 'complement' },
        { text: 'Referência', value: 'reference' },
        { text: 'Cidade', value: 'city.name' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toAddressesPage', {
          business: this.business.id,
          search: this.addressesSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchAddresses();
    },
    methods: {
      fetchAddresses() {
        this.$store.dispatch('fetchAddresses', {
          business: this.business.id,
        });
      },
      searchAddresses() {
        this.$store.dispatch('searchAddresses', {
          business: this.business.id,
          search: this.addressesSearch
        });
      },
      destroyAddress(address) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteAddress', {
            business: this.business.id,
            address: address.id
          }).then(() => {
            this.fetchAddresses();
          });
        }
      },
    },
    computed: {
      addresses() {
        return this.$store.getters.getAddresses;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
