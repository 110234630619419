<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Chave Pix</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Chave Pix</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-select v-model="payload.type" label="Tipo de Chave*" :items="selectTypes"
                                :error-messages="formErrors.type" @change="payload.key = ''"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6" v-if="payload.type === 'CPF'">
                  <v-text-field v-model="payload.key" label="CPF*" type="number"
                                :error-messages="formErrors.key"
                                hide-details="auto" :rules="[$rules.required, $rules.cpf]"/>
                </v-col>
                <v-col cols="12" md="6" v-if="payload.type === 'CNPJ'">
                  <v-text-field v-model="payload.key" label="CNPJ*" type="number"
                                :error-messages="formErrors.key"
                                hide-details="auto" :rules="[$rules.required, $rules.cnpj]"/>
                </v-col>
                <v-col cols="12" md="6" v-if="payload.type === 'EMAIL'">
                  <v-text-field v-model="payload.key" label="Email*" type="text"
                                :error-messages="formErrors.key"
                                hide-details="auto" :rules="[$rules.required, $rules.email]"/>
                </v-col>
                <v-col cols="12" md="6" v-if="payload.type === 'TELEPHONE'">
                  <v-text-field v-model="payload.key" label="Telefone*" type="number"
                                :error-messages="formErrors.key"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6" v-if="payload.type === 'RANDOM'">
                  <v-text-field v-model="payload.key" label="Chave Aleatória*" type="text"
                                :error-messages="formErrors.key"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storePix()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updatePix()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-pix-form',
    data: () => ({
      formErrors: {},
      payload: {
        type: 'CPF',
        key: '',
      },
      selectTypes: [
        { text: 'CPF', value: 'CPF' },
        { text: 'CNPJ', value: 'CNPJ' },
        { text: 'Email', value: 'EMAIL' },
        { text: 'Telefone', value: 'TELEPHONE' },
        { text: 'Chave Aleatória', value: 'RANDOM' },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchPix', {
          business: this.business.id,
          pix: this.$route.params.pix
        }).then((response) => {
          this.payload = {
            type: response.data.data.type,
            key: response.data.data.key,
          }
        });
      }
    },
    methods: {
      storePix() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createPix', {
          business: this.business.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updatePix() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updatePix', {
          business: this.business.id,
          pix: this.$route.params.pix,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'pixes_create';
      },
      updating() {
       return this.$route.name === 'pixes_update';
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
