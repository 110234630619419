<template>
  <v-autocomplete v-bind="$attrs" v-model="bankSelected" :items="banks" item-text="name" item-value="id"
                  :loading="loading"/>
</template>

<script>
  import resource from "../resource";

  export default {
    inheritAttrs: false,
    name: 'a-bank-search',
    props: ['value'],
    data: () => ({
      banksSearch: '',
      bankSelected: null,
      banks: [],
      loading: false,
    }),
    created() {
      this.loading = true;
      resource.get('/banks', { params: { all: 1 } }).then((response) => {
        this.loading = false;
        this.banks = response.data.data;
      });
      if (this.value) {
        this.bankSelected = this.value;
      }
    },
    watch: {
      value(value) {
        this.bankSelected = value;
      },
      bankSelected(value) {
        this.$emit('input', value);
      },
    },
    computed: {},
  }
</script>
