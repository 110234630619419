<template>
  <v-item-group v-model="selected">
    <v-row dense>
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Planos</h1>
      </v-col>
      <v-col cols="12" md="6" lg="3" v-for="plan in plans.data" :key="`plan-${plan.id}`">
        <v-item :value="plan.id" v-slot:default="{ active, toggle }">
          <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
            <v-card-title>
              {{ plan.name }}
            </v-card-title>
            <v-card-subtitle>
              {{ new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(plan.amount) }}
            </v-card-subtitle>
            <v-card-text>
              Intervalo: {{ planInterval(plan.interval) }}
              <v-divider/>
              Parcelas: {{ plan.iterations }}
              <v-divider/>
              Teste: {{ plan.trial_period_days }} Dias
              <template v-if="plan.valid_until">
                <v-divider/>
                Valido Até: {{ new Intl.DateTimeFormat('pt-BR').format(new Date(plan.valid_until)) }}
              </template>
            </v-card-text>
          </v-card>
        </v-item>
      </v-col>

      <template v-if="businessPlans && businessPlans.data && businessPlans.data.length">
        <v-col cols="12">
          <h1 class="text-subtitle-1 font-weight-light text-uppercase">Condições Especiais</h1>
        </v-col>
        <v-col cols="12" md="6" lg="3" v-for="plan in businessPlans.data" :key="`business-plan-${plan.id}`">
          <v-item :value="plan.id" v-slot:default="{ active, toggle }">
            <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
              <v-card-title>
                {{ plan.name }}
              </v-card-title>
              <v-card-subtitle>
                {{ new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(plan.amount) }}
              </v-card-subtitle>
              <v-card-text>
                Intervalo: {{ planInterval(plan.interval) }}
                <v-divider/>
                Parcelas: {{ plan.iterations }}
                <v-divider/>
                Teste: {{ plan.trial_period_days }} Dias
                <template v-if="plan.valid_until">
                  <v-divider/>
                  Valido Até: {{ new Intl.DateTimeFormat('pt-BR').format(new Date(plan.valid_until)) }}
                </template>
              </v-card-text>
            </v-card>
          </v-item>
        </v-col>
      </template>
    </v-row>
  </v-item-group>
</template>

<script>
  export default {
    inheritAttrs: false,
    name: 'a-plan-search',
    props: ['value'],
    data: () => ({
      search: '',
      selected: null,
      plans: [],
      businessPlans: [],
      loading: false,
    }),
    async created() {
      this.loading = true;

      const plans = await this.$axios.get(`/plans`, { params: { all: 1 } });
      this.plans = plans.data;

      const businessPlans = await this.$axios.get(`/businesses/${this.business.id}/plans`, { params: { all: 1 } });
      this.businessPlans = businessPlans.data;

      if (this.value) {
        this.selected = this.value;
      }

      this.loading = false;
    },
    watch: {
      value(value) {
        this.selected = value;
      },
      selected(value) {
        this.$emit('input', value);
      },
    },
    methods: {
      planInterval(interval) {
        switch (interval) {
          case 'MONTH':
            return 'Mensal';
          case 'YEAR':
            return 'Anual';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  }
</script>
