<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Chave Pix</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'pixes_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="pixes">
      <v-col cols="12">
        <v-text-field v-model="pixesSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchPixes()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="pixesHeaders"
                    :items="pixes.data"
                    :page.sync="pixes.meta.current_page"
                    :items-per-page.sync="pixes.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.type="{ item }">
            {{ keyType(item.type) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'pixes_update', params: { pix: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyPix(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="pixes.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-pix-list',
    data: () => ({
      page: 1,
      pixesSearch: '',
      pixesHeaders: [
        { text: 'Tipo de Chave', value: 'type' },
        { text: 'Chave', value: 'key' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toPixesPage', {
          business: this.business.id,
          search: this.pixesSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchPixes();
    },
    methods: {
      fetchPixes() {
        this.$store.dispatch('fetchPixes', {
          business: this.business.id,
        });
      },
      searchPixes() {
        this.$store.dispatch('searchPixes', {
          business: this.business.id,
          search: this.pixesSearch
        });
      },
      destroyPix(pix) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deletePix', {
            business: this.business.id,
            pix: pix.id
          }).then(() => {
            this.fetchPixes();
          });
        }
      },
      keyType(type){
        switch (type) {
          case 'CPF':
            return 'CPF';
          case 'CNPJ':
            return 'CNPJ';
          case 'EMAIL':
            return 'Email';
          case 'TELEPHONE':
            return 'Telefone';
          case 'RANDOM':
            return 'Chave Aleatória';
          default:
            return 'Indefinido';
        }
      },
    },
    computed: {
      pixes() {
        return this.$store.getters.getPixes;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
