<template>
  <v-row dense v-if="payload" align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense>
              <v-col cols="12">
                <h1 class="text-subtitle-1 font-weight-light text-uppercase">Opções de Fidelidade</h1>
              </v-col>
              <v-col cols="12">
                <v-switch inset v-model="payload.enable_loyalty" label="Habilitar fidelidade"
                          :error-messages="formErrors.enable_loyalty"
                          hide-details="auto"/>
              </v-col>
              <v-col cols="12" v-if="payload.enable_loyalty">
                <v-text-field v-model="payload.loyalty_expire_days" label="Pontos de fidelidade expiram em*"
                              type="number"
                              :error-messages="formErrors.loyalty_expire_days"
                              hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
          <v-btn depressed color="primary" @click="updateProfile()">Alterar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-profile-about-update',
  data: () => ({
    formErrors: {},
    payload: {
      enable_loyalty: '',
      loyalty_expire_days: '',
    },
    valid: true,
  }),
  created() {
    this.$store.dispatch('fetchProfile', {
      business: this.business.id
    }).then((response) => {
      this.payload = {
        enable_loyalty: response.data.data.enable_loyalty,
        loyalty_expire_days: response.data.data.loyalty_expire_days
      }
    });
  },
  methods: {
    updateProfile() {
      if (!this.$refs.form.validate()) {
        alert('Verifique os campos do formulário!');
        return;
      }
      this.$store.dispatch('updateProfile', {
        business: this.business.id,
        payload: this.payload
      }).then((response) => {
        this.$refs.form.reset();
        this.$store.dispatch('loginSetBusiness', response.data.data);
        this.$router.back();
      });
    },
  },
  computed: {
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    }
  }
};
</script>
