<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Conta de Banco</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'bank_accounts_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="bankAccounts">
      <v-col cols="12">
        <v-text-field v-model="bankAccountsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchBankAccounts()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="bankAccountsHeaders"
                    :items="bankAccounts.data"
                    :page.sync="bankAccounts.meta.current_page"
                    :items-per-page.sync="bankAccounts.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.legal_type="{ item }">
            {{ bankAccountLegalType(item.legal_type) }}
          </template>
          <template v-slot:item.branch="{ item }">
            <span v-if="item.branch_digit">{{ item.branch }} - {{ item.branch_digit }}</span>
            <span v-else>{{ item.branch }}</span>
          </template>
          <template v-slot:item.account="{ item }">
            <span v-if="item.account_digit">{{ item.account }} - {{ item.account_digit }}</span>
            <span v-else>{{ item.account }}</span>
          </template>
          <template v-slot:item.account_type="{ item }">
            {{ bankAccountType(item.account_type) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'bank_accounts_update', params: { bankAccount: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyBankAccount(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="bankAccounts.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-bank-account-list',
    data: () => ({
      page: 1,
      bankAccountsSearch: '',
      bankAccountsHeaders: [
        { text: 'Nome', value: 'legal_name' },
        { text: 'Tipo de Usuário', value: 'legal_type' },
        { text: 'Nº da Agência', value: 'branch' },
        { text: 'Nº da Conta', value: 'account' },
        { text: 'Tipo de Conta', value: 'account_type' },
        { text: 'Banco', value: 'bank.name' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toBankAccountsPage', {
          business: this.business.id,
          search: this.bankAccountsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchBankAccounts();
    },
    methods: {
      fetchBankAccounts() {
        this.$store.dispatch('fetchBankAccounts', {
          business: this.business.id,
        });
      },
      searchBankAccounts() {
        this.$store.dispatch('searchBankAccounts', {
          business: this.business.id,
          search: this.bankAccountsSearch
        });
      },
      destroyBankAccount(bankAccount) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteBankAccount', {
            business: this.business.id,
            bankAccount: bankAccount.id
          }).then(() => {
            this.fetchBankAccounts();
          });
        }
      },
      bankAccountLegalType(type) {
        switch (type) {
          case 'INDIVIDUAL':
            return 'Física';
          case 'COMPANY':
            return 'Jurídica';
          default:
            return 'Indefinido';
        }
      },
      bankAccountType(type) {
        switch (type) {
          case 'CHECKING':
            return 'Corrente';
          case 'SAVINGS':
            return 'Poupança';
          default:
            return 'Indefinido';
        }
      },
    },
    computed: {
      bankAccounts() {
        return this.$store.getters.getBankAccounts;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
