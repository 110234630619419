<template>
  <v-card flat outlined class="fill-height">
    <v-card-title>Descrição</v-card-title>
    <v-card-text v-if="business">
      {{ business.description }}
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    inheritAttrs: false,
    name: 'a-business-info-widget',
    computed: {
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      }
    }
  }
</script>
