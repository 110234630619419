import AWithdrawalList from './index'
import AWithdrawalForm from './form'

export default [
  {
    path: '/withdrawals/',
    component: AWithdrawalList,
    name: 'withdrawals',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/withdrawals/create',
    component: AWithdrawalForm,
    name: 'withdrawals_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/withdrawals/:withdrawal/update',
    component: AWithdrawalForm,
    name: 'withdrawals_update',
    meta: {
      requiresAuth: true
    }
  },
];
