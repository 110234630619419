import resource from '../../resource';

const state = {
  pixes: null,
};

const getters = {
  getPixes(state) {
    return state.pixes;
  },
};

const mutations = {
  SET_PIXES(state, data) {
    state.pixes = data;
  },
};

const actions = {
  fetchPixes(context, { business }) {
    return resource.get(`/businesses/${business}/pixes`)
      .then((response) => {
        context.commit('SET_PIXES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchPix(context, { business, pix }) {
    return resource.get(`/businesses/${business}/pixes/${pix}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchPixes(context, { business, search }) {
    return resource.get(`/businesses/${business}/pixes`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_PIXES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toPixesPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/pixes`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_PIXES', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createPix(context, { business, payload }) {
    return resource.post(`/businesses/${business}/pixes`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updatePix(context, { business, pix, payload }) {
    return resource.put(`/businesses/${business}/pixes/${pix}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deletePix(context, { business, pix }) {
    return resource.delete(`/businesses/${business}/pixes/${pix}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
