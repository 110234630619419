import ATelephoneList from './index'
import ATelephoneForm from './form'

export default [
  {
    path: '/telephones/',
    component: ATelephoneList,
    name: 'telephones',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/telephones/create',
    component: ATelephoneForm,
    name: 'telephones_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/telephones/:telephone/update',
    component: ATelephoneForm,
    name: 'telephones_update',
    meta: {
      requiresAuth: true
    }
  },
];
