<template>
  <v-row dense v-if="payload" align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense>
              <v-col cols="12">
                <h1 class="text-subtitle-1 font-weight-light text-uppercase">Sobre o Estabelecimento</h1>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model.trim="payload.name"
                              :error-messages="formErrors.name"
                              label="Nome do estabelecimento*" :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select v-model="payload.legal_type" :items="legalTypes"
                          :error-messages="formErrors.legal_type"
                          label="Tipo de Conta*" :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="payload.legal_type === 'INDIVIDUAL'">
                <v-text-field v-model.trim="payload.legal_name" label="Nome*'"
                              :error-messages="formErrors.legal_name"
                              :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="payload.legal_type === 'COMPANY'">
                <v-text-field v-model.trim="payload.legal_name" label="Razão*"
                              :error-messages="formErrors.legal_name"
                              :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" sm="12" md="4" v-if="payload.legal_type === 'INDIVIDUAL'">
                <v-text-field v-model="payload.cpf"
                              :error-messages="formErrors.cpf"
                              label="Numero do Documento CPF*" type="number" :rules="[$rules.required, $rules.cpf]"/>
              </v-col>
              <v-col cols="12" sm="12" md="4" v-if="payload.legal_type === 'COMPANY'">
                <v-text-field v-model="payload.cnpj"
                              :error-messages="formErrors.cnpj"
                              label="Numero do Documento CNPJ*" type="number" :rules="[$rules.required, $rules.cnpj]"/>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <a-category-search label="Categoria*"
                                   :error-messages="formErrors.category_id" v-model="payload.category_id"
                                   :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="payload.min_order_amount"
                              :error-messages="formErrors.min_order_amount"
                              label="Preço mínimo de compra*" prefix="R$" type="number" :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12">
                <v-textarea auto-grow v-model="payload.description" label="Descrição" rows="1"
                            :error-messages="formErrors.description"/>
              </v-col>
              <v-col cols="12" md="6">
                <a-address-search v-model="payload.address_id" label="Endereço*"
                                  :error-messages="formErrors.address_id"
                                  :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" md="6">
                <a-telephone-search v-model="payload.telephone_id" label="Telefone*"
                                    :error-messages="formErrors.telephone_id"
                                    :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12">
                <v-switch inset v-model="payload.active" label="Visível no Aplicativo" :error-messages="formErrors.active"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
          <v-btn depressed color="primary" @click="updateProfile()">Alterar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import ACategorySearch from "@/components/ACategorySearch";
  import ATelephoneSearch from "@/components/ATelephoneSearch";
  import AAddressSearch from "@/components/AAddressSearch";

  export default {
    name: 'a-profile-about-update',
    components: {
      AAddressSearch,
      ATelephoneSearch,
      ACategorySearch
    },
    data: () => ({
      formErrors: {},
      dialog: false,
      payload: null,
      legalTypes: [
        { text: 'Física', value: 'INDIVIDUAL' },
        { text: 'Jurídica', value: 'COMPANY' },
      ],
      valid: true,
    }),
    created() {
      this.$store.dispatch('fetchProfile', {
        business: this.business.id
      }).then((response) => {
        this.payload = {
          name: response.data.data.name,
          legal_name: response.data.data.legal_name,
          legal_type: response.data.data.legal_type,
          cpf: response.data.data.cpf,
          cnpj: response.data.data.cnpj,
          min_order_amount: response.data.data.min_order_amount,
          description: response.data.data.description,
          active: response.data.data.active,
          category_id: response.data.data.category ? response.data.data.category.id : '',
          address_id: response.data.data.address ? response.data.data.address.id : '',
          telephone_id: response.data.data.telephone ? response.data.data.telephone.id : '',
          thumbnail_id: response.data.data.thumbnail ? response.data.data.thumbnail.id : '',
        }
      });
    },
    methods: {
      updateProfile() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateProfile', {
          business: this.business.id,
          payload: this.payload
        }).then((response) => {
          this.$refs.form.reset();
          this.$store.dispatch('loginSetBusiness', response.data.data);
          this.$router.back();
        }).catch((error) => {
          if(error.response && error.response.status === 422){
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
      categories() {
        return this.$store.getters.getProfileCategories;
      },
      addresses() {
        return this.$store.getters.getProfileAddresses;
      },
      telephones() {
        return this.$store.getters.getProfileTelephones;
      },
      thumbnails() {
        return this.$store.getters.getProfileThumbnails;
      },
    }
  };
</script>
