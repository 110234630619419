import ABankAccountList from './index'
import ABankAccountForm from './form'

export default [
  {
    path: '/bank_accounts/',
    component: ABankAccountList,
    name: 'bank_accounts',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/bank_accounts/create',
    component: ABankAccountForm,
    name: 'bank_accounts_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bank_accounts/:bankAccount/update',
    component: ABankAccountForm,
    name: 'bank_accounts_update',
    meta: {
      requiresAuth: true
    }
  },
];
