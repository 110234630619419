<template>
  <v-card flat outlined class="fill-height">
    <v-card-title>Pagamentos aceitos na entrega</v-card-title>
    <v-card-text>
      <v-row dense>
        <template v-if="paymentMethodsCurrency">
          <v-col cols="12">
            <h1 class="text-subtitle-1 font-weight-light text-uppercase">Dinheiro</h1>
          </v-col>

          <v-col cols="12">
            <v-chip label v-for="paymentMethod in paymentMethodsCurrency" :key="paymentMethod.id">
              <v-avatar left>
                <v-icon>mdi-cash</v-icon>
              </v-avatar>
              {{ paymentMethod.name }}
            </v-chip>
          </v-col>
        </template>
        <template v-if="paymentMethodsCreditCard">
          <v-col cols="12">
            <h1 class="text-subtitle-1 font-weight-light text-uppercase">Cartão de Crédito</h1>
          </v-col>

          <v-col cols="12">
            <v-chip label class="mr-2 mb-2" v-for="paymentMethod in paymentMethodsCreditCard" :key="paymentMethod.id">
              <v-avatar tile left>
                <v-icon>mdi-credit-card</v-icon>
              </v-avatar>
              {{ paymentMethod.name }}
            </v-chip>
          </v-col>
        </template>
        <template v-if="paymentMethodsDebitCard">
          <v-col cols="12">
            <h1 class="text-subtitle-1 font-weight-light text-uppercase">Cartão de Débito</h1>
          </v-col>

          <v-col cols="12">
            <v-chip label class="mr-2 mb-2" v-for="paymentMethod in paymentMethodsDebitCard" :key="paymentMethod.id">
              <v-avatar tile left>
                <v-icon>mdi-credit-card</v-icon>
              </v-avatar>
              {{ paymentMethod.name }}
            </v-chip>
          </v-col>
        </template>
        <template v-if="paymentMethodsMealTicket">
          <v-col cols="12">
            <h1 class="text-subtitle-1 font-weight-light text-uppercase">Ticket</h1>
          </v-col>

          <v-col cols="12">
            <v-chip label class="mr-2 mb-2" v-for="paymentMethod in paymentMethodsMealTicket" :key="paymentMethod.id">
              <v-avatar tile left>
                <v-icon>mdi-credit-card</v-icon>
              </v-avatar>
              {{ paymentMethod.name }}
            </v-chip>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    inheritAttrs: false,
    name: 'a-business-payment-methods-widget',
    data: () => ({
      paymentMethodsCreditCard: null,
      paymentMethodsDebitCard: null,
      paymentMethodsMealTicket: null,
      paymentMethodsCurrency: null,
    }),
    async created() {
      const paymentMethods = await this.$axios.get(`/businesses/${this.business.id}/payment_methods`, {params: {all: 1}});
      this.paymentMethodsCreditCard = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'CREDIT_CARD')
      this.paymentMethodsDebitCard = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'DEBIT_CARD')
      this.paymentMethodsMealTicket = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'MEAL_TICKET')
      this.paymentMethodsCurrency = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'CURRENCY')
    },
    computed: {
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      }
    }
  }
</script>
