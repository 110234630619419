<template>
  <v-card flat outlined class="fill-height">
    <v-card-title>Horários de funcionamento na semana</v-card-title>
    <v-card-text v-if="periods">
      <v-data-table
          :headers="periodsHeaders"
          :items="periods.data"
          hide-default-footer
          disable-sort>
        <template v-slot:item.day="{ item }">
          {{ periodDays(item.day) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'a-business-periods-widget',
  data: () => ({
    periodsHeaders: [
      { text: 'Dia', value: 'day' },
      { text: 'Hora Início', value: 'time_start' },
      { text: 'Hora Fim', value: 'time_end' }
    ]
  }),
  created() {
    this.$store.dispatch('fetchBusinessPeriods', {
      business: this.business.id,
    });
  },
  methods: {
    searchPeriods() {
      this.$store.dispatch('searchPeriods', {
        business: this.business.id,
        name: this.periodsSearch
      });
    },
    destroyPeriod(period) {
      if (confirm('Tem certeza que deseja excluir esse item?')) {
        this.$store.dispatch('deletePeriod', {
          business: this.business.id,
          period: period.id
        }).then(() => {
          this.$store.dispatch('fetchBusinessPeriods', {
            business: this.business.id
          });
        });
      }
    },
    periodDays(day) {
      switch (day) {
        case 1:
          return 'Domingo';
        case 2:
          return 'Segunda';
        case 3:
          return 'Terça';
        case 4:
          return 'Quarta';
        case 5:
          return 'Quinta';
        case 6:
          return 'Sexta';
        case 7:
          return 'Sábado';
        default:
          return 'Indefinido';
      }
    },
  },
  computed: {
    periods() {
      return this.$store.getters.getBusinessPeriods;
    },
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    }
  }
}
</script>
