<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Inscrição</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Inscrição</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12">
                  <a-plan-search v-model="payload.plan_id" label="Plano*" type="text" :disabled="updating"
                                 :error-messages="formErrors.plan_id"
                                 hide-details="auto" :rules="creating ? [$rules.required] : []"/>
                </v-col>
                <v-col cols="12">
                  <a-card-search v-model="payload.card_id" label="Cartão*" type="text"
                                 :error-messages="formErrors.card_id"
                                 hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeSubscription()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateSubscription()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import APlanSearch from "@/components/APlanSearch";
  import ACardSearch from "@/components/ACardSearch";
  export default {
    name: 'a-subscription-form',
    components: {ACardSearch, APlanSearch},
    data: () => ({
      formErrors: {},
      payload: {
        plan_id: '',
        card_id: '',
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.fetchSubscription();
      }
    },
    methods: {
      async fetchSubscription() {
        const subscription = await this.$axios.get(`/businesses/${this.business.id}/subscriptions/${this.$route.params.subscription}`)
        this.payload = {
          plan_id: subscription.data.data.plan ? subscription.data.data.plan.id : '',
          card_id: subscription.data.data.card ? subscription.data.data.card.id : '',
        }
      },
      async storeSubscription() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.post(`/businesses/${this.business.id}/subscriptions`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch (error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
      async updateSubscription() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.put(`/businesses/${this.business.id}/subscriptions/${this.$route.params.subscription}`, this.payload);
          this.$refs.form.reset();
          this.$router.back();
        } catch(error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'subscriptions_create';
      },
      updating() {
       return this.$route.name === 'subscriptions_update';
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
