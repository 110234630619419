import resource from '../../resource';

const state = {
  cards: null,
};

const getters = {
  getCards(state) {
    return state.cards;
  },
};

const mutations = {
  SET_CARDS(state, data) {
    state.cards = data;
  },
};

const actions = {
  fetchCards(context, { business }) {
    return resource.get(`businesses/${business}/cards`)
      .then((response) => {
        context.commit('SET_CARDS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchCard(context, { business, card }) {
    return resource.get(`businesses/${business}/cards/${card}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchCards(context, { business, search }) {
    return resource.get(`businesses/${business}/cards`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_CARDS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toCardsPage(context, { business, search, page }) {
    return resource.get(`businesses/${business}/cards`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_CARDS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createCard(context, { business, payload }) {
    return resource.post(`businesses/${business}/cards`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateCard(context, { business, card, payload }) {
    return resource.put(`businesses/${business}/cards/${card}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteCard(context, { business, card }) {
    return resource.delete(`businesses/${business}/cards/${card}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
