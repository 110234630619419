import resource from "../../resource";
const tokenKeyName = 'PUB_ADMIN_TOKEN';
const businessKeyName = 'PUB_ADMIN_BUSINESS';

const state = {
  token: null,
  business: null
};

const getters = {
  getLoginToken(state) {
    if (localStorage.getItem(tokenKeyName)){
      state.token = localStorage.getItem(tokenKeyName);
    }
    return state.token;
  },
  getLoginAuthBusiness(state) {
    if (localStorage.getItem(businessKeyName)){
      state.business = JSON.parse(localStorage.getItem(businessKeyName));
    }
    return state.business;
  },
  getLoginAuthCheck(state) {
    return !!state.token || !!localStorage.getItem(tokenKeyName);
  },
};

const mutations = {
  SET_LOGIN_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem(tokenKeyName, token);
  },
  REMOVE_LOGIN_TOKEN(state) {
    state.token = null;
    localStorage.removeItem(tokenKeyName);
  },
  SET_LOGIN_BUSINESS(state, business) {
    state.business = business;
    localStorage.setItem(businessKeyName, JSON.stringify(business));
  },
  REMOVE_LOGIN_BUSINESS(state) {
    state.business = null;
    localStorage.removeItem(businessKeyName);
  },
};

const actions = {
  loginCreateSession(context, credentials) {
    return resource.post('/auth/business/login', credentials)
      .then((response) => {
        context.commit('SET_LOGIN_TOKEN', response.data.access_token);
        return context.dispatch('loginFetchAuthBusiness');
      }).catch((error) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveBusiness');
        return Promise.reject(error);
      });
  },
  loginRemoveSession(context) {
    return resource.post('/auth/business/logout')
      .then((response) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveBusiness');
        return Promise.resolve(response);
      }).catch((error) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveBusiness');
        return Promise.reject(error);
      });
  },
  loginRemoveSessions(context) {
    return resource.post('/auth/business/revoke')
      .then((response) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveBusiness');
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  loginFetchAuthBusiness(context) {
    return resource.get('/auth/business/me')
      .then((response) => {
        context.dispatch('loginSetBusiness', response.data.data);
        return Promise.resolve(response);
      }).catch((error) => {
        context.dispatch('loginRemoveSession');
        return Promise.reject(error);
      });
  },
  loginSetToken(context, data) {
    context.commit('SET_LOGIN_TOKEN', data);
  },
  loginRemoveToken(context) {
    context.commit('REMOVE_LOGIN_TOKEN');
  },
  loginRemoveBusiness(context) {
    context.commit('REMOVE_LOGIN_BUSINESS');
  },
  loginSetBusiness(context, data) {
    context.commit('SET_LOGIN_BUSINESS', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
