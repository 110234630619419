<template>
  <v-row dense>
    <v-col cols="12">
      <v-item-group multiple v-model="paymentMethodsSelected">
        <v-row dense>
          <v-col cols="12">
            <h1 class="text-subtitle-1 font-weight-light text-uppercase">Dinheiro</h1>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2" xl="1" v-for="paymentMethod in paymentMethodsCurrency" :key="paymentMethod.id">
            <v-item :value="paymentMethod.id" v-slot:default="{ active, toggle }">
              <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-icon>mdi-cash</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ paymentMethod.name }}</v-list-item-title>
                    <v-list-item-subtitle>Moeda</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-item>
          </v-col>

          <v-col cols="12">
            <h1 class="text-subtitle-1 font-weight-light text-uppercase">Cartão de Crédito</h1>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2" xl="1" v-for="paymentMethod in paymentMethodsCreditCard" :key="paymentMethod.id">
            <v-item :value="paymentMethod.id" v-slot:default="{ active, toggle }">
              <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-icon>mdi-credit-card</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ paymentMethod.name }}</v-list-item-title>
                    <v-list-item-subtitle>Cartão de Crédito</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-item>
          </v-col>

          <v-col cols="12">
            <h1 class="text-subtitle-1 font-weight-light text-uppercase">Cartão de Débito</h1>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2" xl="1" v-for="paymentMethod in paymentMethodsDebitCard" :key="paymentMethod.id">
            <v-item :value="paymentMethod.id" v-slot:default="{ active, toggle }">
              <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-icon>mdi-credit-card</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ paymentMethod.name }}</v-list-item-title>
                    <v-list-item-subtitle>Cartão de Débito</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-item>
          </v-col>

          <v-col cols="12">
            <h1 class="text-subtitle-1 font-weight-light text-uppercase">Vale Refeição</h1>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2" xl="1" v-for="paymentMethod in paymentMethodsMealTicket" :key="paymentMethod.id">
            <v-item :value="paymentMethod.id" v-slot:default="{ active, toggle }">
              <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-icon>mdi-credit-card</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ paymentMethod.name }}</v-list-item-title>
                    <v-list-item-subtitle>Vale Refeição</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'a-payment-method-select',
    props: ['value'],
    data: () => ({
      paymentMethodsCreditCard: null,
      paymentMethodsDebitCard: null,
      paymentMethodsMealTicket: null,
      paymentMethodsCurrency: null,
      paymentMethodsSelected: [],
    }),
    async created() {
      this.paymentMethodsSelected = this.value ? this.value : [];

      const paymentMethods = await this.$axios.get(`/payment_methods`, {params: {all: 1}});
      this.paymentMethodsCreditCard = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'CREDIT_CARD')
      this.paymentMethodsDebitCard = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'DEBIT_CARD')
      this.paymentMethodsMealTicket = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'MEAL_TICKET')
      this.paymentMethodsCurrency = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'CURRENCY')
    },
    watch: {
      paymentMethodsSelected(value) {
        this.$emit('input', value);
      },
    },
  };
</script>
