import resource from '../../resource';

const state = {
  bankAccounts: null,
};

const getters = {
  getBankAccounts(state) {
    return state.bankAccounts;
  },
};

const mutations = {
  SET_BANK_ACCOUNTS(state, data) {
    state.bankAccounts = data;
  },
};

const actions = {
  fetchBankAccounts(context, { business }) {
    return resource.get(`/businesses/${business}/bank_accounts`)
      .then((response) => {
        context.commit('SET_BANK_ACCOUNTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchBankAccount(context, { business, bankAccount }) {
    return resource.get(`/businesses/${business}/bank_accounts/${bankAccount}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchBankAccounts(context, { business, search }) {
    return resource.get(`/businesses/${business}/bank_accounts`, { params: { legal_name: search } })
      .then((response) => {
        context.commit('SET_BANK_ACCOUNTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toBankAccountsPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/bank_accounts`, { params: { legal_name: search, page: page } })
      .then((response) => {
        context.commit('SET_BANK_ACCOUNTS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createBankAccount(context, { business, payload }) {
    return resource.post(`/businesses/${business}/bank_accounts`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateBankAccount(context, { business, bankAccount, payload }) {
    return resource.put(`/businesses/${business}/bank_accounts/${bankAccount}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteBankAccount(context, { business, bankAccount }) {
    return resource.delete(`/businesses/${business}/bank_accounts/${bankAccount}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
