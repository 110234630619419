import ASubscriptionList from './index'
import ASubscriptionForm from './form'

export default [
  {
    path: '/subscriptions/',
    component: ASubscriptionList,
    name: 'subscriptions',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/subscriptions/create',
    component: ASubscriptionForm,
    name: 'subscriptions_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/subscriptions/:subscription/update',
    component: ASubscriptionForm,
    name: 'subscriptions_update',
    meta: {
      requiresAuth: true
    }
  },
];
