<template>
  <v-row align-content="center" justify="center" class="fill-height">
    <v-col cols="12" md="8">
      <v-card flat outlined>
        <v-card-title>Registrar Administração</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" @keyup.native.enter="register">
            <v-row dense>
              <v-col cols="12">
                <h1 class="text-subtitle-1 font-weight-light text-uppercase">Sobre o Estabelecimento</h1>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model.trim="payload.name"
                              label="Nome do estabelecimento*" :rules="[$rules.required]"
                              hide-details="auto" :error-messages="formErrors.name"/>
              </v-col>
              <v-col cols="12" sm="6">
                <a-category-search label="Categoria*" v-model="payload.category_id"
                                   :error-messages="formErrors.category_id"
                                   hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select v-model="payload.legal_type" :items="legalTypes"
                          :error-messages="formErrors.legal_type"
                          label="Tipo de Conta*" hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="payload.legal_type === 'INDIVIDUAL'">
                <v-text-field v-model.trim="payload.legal_name" label="Nome*"
                              :error-messages="formErrors.legal_name"
                              hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" sm="6" md="4" v-if="payload.legal_type === 'COMPANY'">
                <v-text-field v-model.trim="payload.legal_name" label="Razão*"
                              :error-messages="formErrors.legal_name"
                              hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
              <v-col cols="12" sm="12" md="4" v-if="payload.legal_type === 'INDIVIDUAL'">
                <v-text-field v-model="payload.cpf" label="Numero do Documento CPF*" type="number"
                              :error-messages="formErrors.cpf"
                              hide-details="auto" :rules="[$rules.required, $rules.cpf]"/>
              </v-col>
              <v-col cols="12" sm="12" md="4" v-if="payload.legal_type === 'COMPANY'">
                <v-text-field v-model="payload.cnpj" label="Numero do Documento CNPJ*" type="number"
                              :error-messages="formErrors.cnpj"
                              hide-details="auto" :rules="[$rules.required, $rules.cnpj]"/>
              </v-col>
              <v-col cols="12" v-if="payload.legal_type === 'INDIVIDUAL'">
                <v-text-field v-model="payload.birth_date" label="Data de Nascimento*" type="date"
                              :error-messages="formErrors.birth_date"
                              hide-details="auto" :rules="[$rules.required]"/>
              </v-col>

              <v-col cols="12">
                <h1 class="text-subtitle-1 font-weight-light text-uppercase">Posição do Estabelecimento</h1>
              </v-col>
              <v-col cols="12">
                <a-leaflet-point v-model="payload.location" hide-details="auto" persistent-hint
                                 hint="Use as ferramentas para sinalizar o local do estabelecimento."/>
              </v-col>

              <v-col cols="12">
                <h1 class="text-subtitle-1 font-weight-light text-uppercase">Informações de Acesso</h1>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="payload.email" label="Email*" type="email"
                              :error-messages="formErrors.email"
                              hide-details="auto" :rules="[$rules.required, $rules.email]"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="payload.password" label="Senha*" type="password"
                              :error-messages="formErrors.password"
                              hide-details="auto" :rules="[$rules.required, $rules.password]"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="payload.password_confirmation" label="Confirmar Senha*" type="password"
                              :error-messages="formErrors.password_confirmation"
                              hide-details="auto" :rules="[$rules.required, $rules.password]"/>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="payload.terms" :rules="[$rules.check]">
                  <template v-slot:label>
                    <div>
                      Aceito os <router-link :to="{ name: 'terms' }" @click.native.stop target="_blank">Termos e Condições</router-link>
                      e as <router-link :to="{ name: 'policies' }" @click.native.stop target="_blank">Politicas de Privacidade</router-link>
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="$router.back()">Voltar</v-btn>
          <v-btn depressed @click="register" color="primary">Cadastrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ACategorySearch from "@/components/ACategorySearch";
import ALeafletPoint from "@/components/ALeafletMapPoint";

export default {
  name: 'a-register',
  components: {
    ALeafletPoint,
    ACategorySearch
  },
  data: () => ({
    formErrors: {},
    payload: {
      name: '',
      legal_name: '',
      legal_type: 'COMPANY',
      cpf: '',
      cnpj: '',
      birth_date: '',
      category_id: '',
      location: '',
      email: '',
      password: '',
      password_confirmation: '',
      terms: false,
    },
    legalTypes: [
      { text: 'Física', value: 'INDIVIDUAL' },
      { text: 'Jurídica', value: 'COMPANY' },
    ],
    valid: true,
  }),
  methods: {
    register() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('register', this.payload)
          .then(() => {
            this.$refs.form.reset();
            this.$router.push({name: 'login'});
          }).catch((error) => {
            if(error.response && error.response.status === 422){
              this.formErrors = error.response.data.errors;
            }
          });
      }
    },
  },
  computed: {},
};
</script>
