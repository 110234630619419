<template>
  <v-autocomplete v-bind="$attrs" v-model="telephoneSelected" :items="telephones" item-text="telephone" item-value="id"
                  :loading="loading"/>
</template>

<script>
  import resource from "../resource";

  export default {
    inheritAttrs: false,
    name: 'a-telephone-search',
    props: ['value'],
    data: () => ({
      telephonesSearch: '',
      telephoneSelected: null,
      telephones: [],
      loading: false,
    }),
    created() {
      this.loading = true;
      resource.get(`businesses/${this.business.id}/telephones`, { params: { all: 1 } }).then((response) => {
        this.loading = false;
        this.telephones = response.data.data;
      });
      if (this.value) {
        this.telephoneSelected = this.value;
      }
    },
    watch: {
      value(value) {
        this.telephoneSelected = value;
      },
      telephoneSelected(value) {
        this.$emit('input', value);
      },
    },
    computed: {
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  }
</script>
