<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Conta de Banco</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Conta de Banco</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.legal_name" label="Nome do titular da conta*" type="text"
                                :error-messages="formErrors.legal_name"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select v-model="payload.legal_type" label="Tipo de Usuário*" :items="selectLegalTypes"
                                :error-messages="formErrors.legal_type"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="4" v-if="payload.legal_type === 'INDIVIDUAL'">
                  <v-text-field v-model="payload.cpf" label="CPF*" type="number"
                                :error-messages="formErrors.cpf"
                                hide-details="auto" :rules="[$rules.required, $rules.cpf]"/>
                </v-col>
                <v-col cols="12" md="4" v-if="payload.legal_type === 'COMPANY'">
                  <v-text-field v-model="payload.cnpj" label="CNPJ*" type="number"
                                :error-messages="formErrors.cnpj"
                                hide-details="auto" :rules="[$rules.required, $rules.cnpj]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select v-model="payload.account_type" label="Tipo de Conta*" :items="selectAccountTypes"
                            :error-messages="formErrors.account_type"
                            hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <a-bank-search v-model="payload.bank_id" label="Banco*" type="text"
                                 :error-messages="formErrors.bank_id"
                                 hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.branch" label="Nº da Agência*" type="text"
                                :error-messages="formErrors.branch"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field v-model="payload.branch_digit" label="Digito da Agência" type="text"
                                :error-messages="formErrors.branch_digit"
                                hide-details="auto"/>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="payload.account" label="Nº da Conta*" type="text"
                                :error-messages="formErrors.account"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field v-model="payload.account_digit" label="Digito da Conta" type="text"
                                :error-messages="formErrors.account_digit"
                                hide-details="auto"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeBankAccount()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateBankAccount()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import ABankSearch from "@/components/ABankSearch";
  export default {
    name: 'a-bank-account-form',
    components: {ABankSearch},
    data: () => ({
      formErrors: {},
      payload: {
        legal_name: '',
        legal_type: 'INDIVIDUAL',
        cpf: '',
        cnpj: '',
        branch: '',
        branch_digit: '',
        account_type: '',
        account: '',
        account_digit: '',
        bank_id: '',
      },
      selectLegalTypes: [
        { text: 'Física', value: 'INDIVIDUAL' },
        { text: 'Jurídica', value: 'COMPANY' },
      ],
      selectAccountTypes: [
        { text: 'Corrente', value: 'CHECKING' },
        { text: 'Poupança', value: 'SAVINGS' },
      ],
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchBankAccount', {
          business: this.business.id,
          bankAccount: this.$route.params.bankAccount
        }).then((response) => {
          this.payload = {
            legal_name: response.data.data.legal_name,
            legal_type: response.data.data.legal_type,
            cpf: response.data.data.cpf,
            cnpj: response.data.data.cnpj,
            branch: response.data.data.branch,
            branch_digit: response.data.data.branch_digit,
            account_type: response.data.data.account_type,
            account: response.data.data.account,
            account_digit: response.data.data.account_digit,
            bank_id: response.data.data.bank ? response.data.data.bank.id : '',
          }
        });
      }
    },
    methods: {
      storeBankAccount() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createBankAccount', {
          business: this.business.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateBankAccount() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateBankAccount', {
          business: this.business.id,
          bankAccount: this.$route.params.bankAccount,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'bank_accounts_create';
      },
      updating() {
       return this.$route.name === 'bank_accounts_update';
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
