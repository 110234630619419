<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Cartão</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'cards_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="cards">
      <v-col cols="12">
        <v-text-field v-model="cardsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchCards()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="cardsHeaders"
                    :items="cards.data"
                    :page.sync="cards.meta.current_page"
                    :items-per-page.sync="cards.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.name="{ item }">
            <span class="text-uppercase">{{ item.name }}</span>
          </template>
          <template v-slot:item.last_four="{ item }">
            **** {{ item.last_four }}
          </template>
          <template v-slot:item.brand="{ item }">
            <v-icon small v-if="item.brand === 'VISA'">icon-visa</v-icon>
            <v-icon small v-if="item.brand === 'MASTERCARD'">icon-mastercard</v-icon>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'cards_update', params: { card: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyCard(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="cards.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-card-list',
    data: () => ({
      page: 1,
      cardsSearch: '',
      cardsHeaders: [
        { text: 'Nome', value: 'name' },
        { text: 'Mês de Vencimento', value: 'exp_month' },
        { text: 'Ano de Vencimento', value: 'exp_year' },
        { text: 'Número', value: 'last_four' },
        { text: 'Bandeira', align: 'center', width: 80, value: 'brand' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toCardsPage', {
          business: this.business.id,
          search: this.cardsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchCards();
    },
    methods: {
      fetchCards() {
        this.$store.dispatch('fetchCards', {
          business: this.business.id,
        });
      },
      searchCards() {
        this.$store.dispatch('searchCards', {
          business: this.business.id,
          search: this.cardsSearch
        });
      },
      destroyCard(card) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteCard', {
            business: this.business.id,
            card: card.id
          }).then(() => {
            this.fetchCards();
          });
        }
      },
    },
    computed: {
      cards() {
        return this.$store.getters.getCards;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
