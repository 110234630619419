<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Saque</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'withdrawals_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="withdrawals">
      <v-col cols="12">
        <v-text-field v-model="withdrawalsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchWithdrawals()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="withdrawalsHeaders"
                    :items="withdrawals.data"
                    :page.sync="withdrawals.meta.current_page"
                    :items-per-page.sync="withdrawals.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.status="{ item }">
            <span :style="{ color: item.status === 'COMPLETED' ? 'green' : 'red' }">{{ withdrawalStatus(item.status) }}</span>
          </template>
          <template v-slot:item.amount="{ item }">
            {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount) }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ DateTime.fromSQL(item.created_at).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
          </template>
          <template v-slot:item.bank="{ item }">
            {{ item.bank_account.bank.name }}
          </template>
          <template v-slot:item.pix="{ item }">
            {{ item.pix.key }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn disabled :to="{ name: 'withdrawals_update', params: { withdrawal: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn disabled @click="destroyWithdrawal(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="withdrawals.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-withdrawal-list',
    data: () => ({
      page: 1,
      withdrawalsSearch: '',
      withdrawalsHeaders: [
        { text: 'Status', value: 'status' },
        { text: 'Valor', value: 'amount' },
        { text: 'data', value: 'created_at' },
        { text: 'Descrição', value: 'description' },
        { text: 'Conta de Banco', value: 'bank_account' },
        { text: 'Pix', value: 'pix' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
      DateTime: DateTime,
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toWithdrawalsPage', {
          business: this.business.id,
          search: this.withdrawalsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchWithdrawals();
    },
    methods: {
      fetchWithdrawals() {
        this.$store.dispatch('fetchWithdrawals', {
          business: this.business.id,
        });
      },
      searchWithdrawals() {
        this.$store.dispatch('searchWithdrawals', {
          business: this.business.id,
          search: this.withdrawalsSearch
        });
      },
      destroyWithdrawal(withdrawal) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteWithdrawal', {
            business: this.business.id,
            withdrawal: withdrawal.id
          }).then(() => {
            this.fetchWithdrawals();
          });
        }
      },
      withdrawalStatus(status){
        switch (status) {
          case 'PENDING':
            return 'Pendente';
          case 'COMPLETED':
            return 'Completo';
          case 'REJECTED':
            return 'Rejeitado';
          default:
            return 'Indefinido';
        }
      },
    },
    computed: {
      withdrawals() {
        return this.$store.getters.getWithdrawals;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
