import AProfile from './index'
import AProfileAboutUpdate from "../../components/Profile/AProfileAboutUpdate";
import AProfileDeliveryUpdate from "../../components/Profile/AProfileDeliveryUpdate";
import AProfileDineInUpdate from "../../components/Profile/AProfileDineInUpdate";
import AProfilePickUpUpdate from "../../components/Profile/AProfilePickUpUpdate";
import AProfileFidelityUpdate from "../../components/Profile/AProfileFidelityUpdate";
import AProfileLocationUpdate from "../../components/Profile/AProfileLocationUpdate";
import AProfilePaymentUpdate from "../../components/Profile/AProfilePaymentUpdate";
import AProfileSecurityUpdate from "../../components/Profile/AProfileSecurityUpdate";

export default [
  {
    path: '/profile',
    name: 'profile',
    component: AProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/about',
    component: AProfileAboutUpdate,
    name: 'profile_about',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/delivery',
    component: AProfileDeliveryUpdate,
    name: 'profile_delivery',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/dine_in',
    component: AProfileDineInUpdate,
    name: 'profile_dine_in',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/pick_up',
    component: AProfilePickUpUpdate,
    name: 'profile_pick_up',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/fidelity',
    component: AProfileFidelityUpdate,
    name: 'profile_fidelity',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/location',
    component: AProfileLocationUpdate,
    name: 'profile_location',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/payment',
    component: AProfilePaymentUpdate,
    name: 'profile_payment',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/security',
    component: AProfileSecurityUpdate,
    name: 'profile_security',
    meta: {
      requiresAuth: true
    }
  }
];

