import resource from '../../resource';

const state = {
  transactions: null,
};

const getters = {
  getTransactions(state) {
    return state.transactions;
  },
};

const mutations = {
  SET_TRANSACTIONS(state, data) {
    state.transactions = data;
  },
};

const actions = {
  fetchTransactions(context, { business }) {
    return resource.get(`/businesses/${business}/transactions`)
      .then((response) => {
        context.commit('SET_TRANSACTIONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchTransaction(context, { business, transaction }) {
    return resource.get(`/businesses/${business}/transactions/${transaction}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchTransactions(context, { business, search }) {
    return resource.get(`/businesses/${business}/transactions`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_TRANSACTIONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toTransactionsPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/transactions`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_TRANSACTIONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createTransaction(context, { business, payload }) {
    return resource.post(`/businesses/${business}/transactions`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateTransaction(context, { business, transaction, payload }) {
    return resource.put(`/businesses/${business}/transactions/${transaction}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteTransaction(context, { business, transaction }) {
    return resource.delete(`/businesses/${business}/transactions/${transaction}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
