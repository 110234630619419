<template>
  <v-row align-content="center" justify="center" class="fill-height">
    <v-col cols="12" sm="8" md="4" style="max-width: 400px;">
      <v-card flat outlined>
        <v-card-title>Login Administração</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" @keyup.native.enter="login">
            <v-row dense>
              <v-col cols="12">
                <v-text-field v-model="credentials.email" label="Email*" type="email"
                              hide-details="auto" :rules="[$rules.required, $rules.email]"/>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="credentials.password" label="Senha*" type="password"
                              hide-details="auto" :rules="[$rules.required, $rules.password]"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn depressed block @click="login" color="primary">Login</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn text :to="{name: 'reset_password'}">Recuperar senha</v-btn>
          <v-spacer/>
          <v-btn text :to="{name: 'register'}">Registrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-login',
  data: () => ({
    credentials: {
      email: '',
      password: '',
      device_name: 'APP'
    },
    valid: true,
  }),
  methods: {
    login() {
      if(this.$refs.form.validate()) {
        this.$store.dispatch('loginCreateSession', this.credentials)
          .then(() => {
            this.$refs.form.reset();
            this.$router.push({ name: 'dashboard' });
          }).catch((error) => {
            if (error.response && error.response.status === 403) {
              this.$router.push({ name: 'email_confirm', query: { email: this.credentials.email } });
            }
          });
      }
    },
  }
};
</script>
