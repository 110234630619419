<template>
  <v-item-group v-model="selected">
    <v-row dense>
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Cartão de Crédito</h1>
      </v-col>
      <v-col cols="12" sm="3" md="2" lg="2" xl="2" v-for="card in cards.data" :key="`card-${card.id}`">
        <v-item :value="card.id" v-slot:default="{ active, toggle }">
          <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ card.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ card.exp_month }}/{{ card.exp_year }}</v-list-item-subtitle>
                <v-list-item-subtitle>**** {{ card.last_four }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon small v-if="card.brand === 'VISA'">icon-visa</v-icon>
                  <v-icon small v-if="card.brand === 'MASTERCARD'">icon-mastercard</v-icon>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-item>
      </v-col>
    </v-row>
  </v-item-group>
</template>

<script>
  export default {
    inheritAttrs: false,
    name: 'a-card-search',
    props: ['value'],
    data: () => ({
      search: '',
      selected: null,
      cards: [],
      loading: false,
    }),
    async created() {
      this.loading = true;

      const cards = await this.$axios.get(`/businesses/${this.business.id}/cards`, { params: { all: 1 } });
      this.cards = cards.data;

      if (this.value) {
        this.selected = this.value;
      }

      this.loading = false;
    },
    watch: {
      value(value) {
        this.selected = value;
      },
      selected(value) {
        this.$emit('input', value);
      },
    },
    methods: {
      cardBrand(brand){
        switch (brand) {
          case 'VISA':
            return 'Visa';
          case 'MASTERCARD':
            return 'Mastercard';
          default:
            return 'Indefinido';
        }
      },
    },
    computed: {
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  }
</script>
