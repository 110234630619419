<template>
  <v-card flat outlined class="fill-height">
    <v-card-title>
      Últimas Avaliações
    </v-card-title>
    <v-card-text v-if="ratings">
      <v-row dense>
        <v-col cols="12" sm="6" md="4" v-for="rating in ratings.data" :key="rating.id">
          <v-list-item two-line class="px-0">
            <v-list-item-content>
              <v-list-item-title>
                <v-rating dense small half-increments readonly background-color="gray lighten-1" v-model="rating.rating"/>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-icon small class="mt-n1">mdi-account</v-icon> {{ rating.comment }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon small class="mt-n1">mdi-domain</v-icon> {{ rating.replica }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    inheritAttrs: false,
    name: 'a-business-ratings-widget',
    data: () => ({
      ratingsSearch: '',
    }),
    created() {
      this.$store.dispatch('fetchBusinessRatings', {
        business: this.business.id,
      });
    },
    computed: {
      ratings() {
        return this.$store.getters.getBusinessRatings;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      }
    },
  };
</script>
