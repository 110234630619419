<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Inscrição</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'subscriptions_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="subscriptions">
      <v-col cols="12">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="fetchSubscriptions()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="headers"
                    :items="subscriptions.data"
                    :page.sync="subscriptions.meta.current_page"
                    :items-per-page.sync="subscriptions.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.status="{ item }">
            {{ subscriptionStatus(item.status) }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ DateTime.fromSQL(item.created_at).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
          </template>
          <template v-slot:item.trial_end="{ item }">
            <span v-if="item.trial_end">{{ DateTime.fromSQL(item.trial_end).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}</span>
          </template>
          <template v-slot:item.current_period_end="{ item }">
            <span v-if="item.current_period_end">{{ DateTime.fromSQL(item.current_period_end).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}</span>
          </template>
          <template v-slot:item.card="{ item }">
            <span v-if="item.card">**** {{ item.card.last_four }}</span>
          </template>
          <template v-slot:item.plan="{ item }">
            <span v-if="item.plan">{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.plan.amount) }} / {{ itemInterval(item.plan.interval) }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'subscriptions_update', params: { subscription: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroySubscription(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="subscriptions.meta.last_page" :total-visible="7" @input="fetchSubscriptions()"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-subscription-list',
    data: () => ({
      subscriptions: null,
      page: 1,
      search: '',
      headers: [
        { text: 'Status', value: 'status' },
        { text: 'Inicio da inscrição', value: 'created_at' },
        { text: 'Fim do teste', value: 'trial_end' },
        { text: 'Fim do ciclo atual', value: 'current_period_end' },
        { text: 'Cartão', value: 'card' },
        { text: 'Plano', value: 'plan' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
      DateTime: DateTime,
    }),
    created() {
      this.fetchSubscriptions();
    },
    methods: {
      async fetchSubscriptions() {
        const subscriptions = await this.$axios.get(`/businesses/${this.business.id}/subscriptions`, { params: { name: this.search, page: this.page } })
        this.subscriptions = subscriptions.data;
      },
      async destroySubscription(subscription) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          await this.$axios.delete(`/businesses/${this.business.id}/subscriptions/${subscription.id}`)
          await this.fetchSubscriptions();
        }
      },
      subscriptionStatus(status){
        switch (status) {
          case 'FREE_TIER':
            return 'Nível gratuito';
          case 'INCOMPLETE':
            return 'Tentativa de pagamento falhou';
          case 'INCOMPLETE_EXPIRED':
            return 'Fatura em aberto cancelada';
          case 'TRIALING':
            return 'Em período de teste';
          case 'ACTIVE':
            return 'Ativo';
          case 'PAST_DUE':
            return 'Tentativa de pagamento falhou';
          case 'CANCELED':
            return 'Cancelado';
          case 'UNPAID':
            return 'Não pago';
          default:
            return 'Indefinido';
        }
      },
      itemInterval(interval) {
        switch (interval) {
          case 'DAY':
            return 'Dia';
          case 'WEEK':
            return 'Semana';
          case 'MONTH':
            return 'Mês';
          case 'YEAR':
            return 'Ano';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
