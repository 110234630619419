import ATransactionList from './index'
import ATransactionForm from './form'

export default [
  {
    path: '/transactions/',
    component: ATransactionList,
    name: 'transactions',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/transactions/create',
    component: ATransactionForm,
    name: 'transactions_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transactions/:transaction/update',
    component: ATransactionForm,
    name: 'transactions_update',
    meta: {
      requiresAuth: true
    }
  },
];
