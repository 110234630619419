<template>
  <v-row dense v-if="payload" align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense>
              <v-col cols="12" class="pt-5">
                <h1 class="text-subtitle-1 font-weight-light text-uppercase">Opções de Entrega em Mesa</h1>
              </v-col>
              <v-col cols="12">
                <v-switch inset v-model="payload.enable_dine_in" label="Habilitar entrega em mesa"
                          :error-messages="formErrors.enable_dine_in"
                          hide-details="auto"/>
              </v-col>
              <template v-if="payload.enable_dine_in">
                <v-col cols="12">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Defina o perímetro do estabelecimento</h1>
                </v-col>
                <v-col cols="12">
                  <a-leaflet-polygon v-model="payload.dine_in_area" persistent-hint
                                     hint="Use as ferramentas para desenhar a área do seu estabelecimento."
                                     :error-messages="formErrors.dine_in_area"
                                     hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="payload.dine_in_min_time" label="Tempo mínimo para servir*"
                                suffix="min" type="number"
                                :error-messages="formErrors.dine_in_min_time"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="payload.dine_in_max_time" label="Tempo máximo para servir*"
                                suffix="min" type="number"
                                :error-messages="formErrors.dine_in_max_time"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
              </template>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
          <v-btn depressed color="primary" @click="updateProfile()">Alterar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ALeafletPolygon from "@/components/ALeafletMapPolygon";

export default {
  name: 'a-profile-about-update',
  components: {
    ALeafletPolygon
  },
  data: () => ({
    formErrors: {},
    payload: {
      enable_dine_in: '',
      dine_in_area: '',
      dine_in_min_time: '',
      dine_in_max_time: '',
    },
    valid: true,
  }),
  created() {
    this.$store.dispatch('fetchProfile', {
      business: this.business.id
    }).then((response) => {
      this.payload = {
        enable_dine_in: response.data.data.enable_dine_in,
        dine_in_area: response.data.data.dine_in_area,
        dine_in_min_time: response.data.data.dine_in_min_time,
        dine_in_max_time: response.data.data.dine_in_max_time,
      }
    });
  },
  methods: {
    updateProfile() {
      if (!this.$refs.form.validate()) {
        alert('Verifique os campos do formulário!');
        return;
      }
      this.$store.dispatch('updateProfile', {
        business: this.business.id,
        payload: this.payload
      }).then((response) => {
        this.$refs.form.reset();
        this.$store.dispatch('loginSetBusiness', response.data.data);
        this.$router.back();
      }).catch((error) => {
        if(error.response && error.response.status === 422){
          this.formErrors = error.response.data.errors;
        }
      });
    },
  },
  computed: {
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    }
  }
};
</script>
