<template>
  <v-row dense v-if="payload" align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense>
              <v-col cols="12">
                <h1 class="text-subtitle-1 font-weight-light text-uppercase">Posição do Estabelecimento</h1>
              </v-col>
              <v-col cols="12" v-if="payload.location">
                <a-leaflet-point v-model="payload.location"  persistent-hint
                                 hint="Use as ferramentas para sinalizar o local do estabelecimento."
                                 :error-messages="formErrors.location"
                                 hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
          <v-btn depressed color="primary" @click="updateProfile()">Alterar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ALeafletPoint from "@/components/ALeafletMapPoint";

export default {
  name: 'a-profile-about-update',
  components: {ALeafletPoint},
  data: () => ({
    formErrors: {},
    payload: {
      location: '',
    },
    valid: true,
  }),
  created() {
    this.$store.dispatch('fetchProfile', {
      business: this.business.id
    }).then((response) => {
      this.payload = {
        location: response.data.data.location
      }
    });
  },
  methods: {
    updateProfile() {
      if (!this.$refs.form.validate()) {
        alert('Verifique os campos do formulário!');
        return;
      }
      this.$store.dispatch('updateProfile', {
        business: this.business.id,
        payload: this.payload
      }).then((response) => {
        this.$refs.form.reset();
        this.$store.dispatch('loginSetBusiness', response.data.data);
        this.$router.back();
      }).catch((error) => {
        if(error.response && error.response.status === 422){
          this.formErrors = error.response.data.errors;
        }
      });
    },
  },
  computed: {
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    }
  }
};
</script>
