<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title v-if="creating">Criar Saque</v-list-item-title>
            <v-list-item-title v-if="updating">Alterar Saque</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn @click="$router.back()" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="payload">
      <template v-if="transactions">
        <v-col cols="12" md="4">
          <v-card flat outlined>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Disponível</v-list-item-title>
                <v-list-item-subtitle>{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(transactions.meta.amount_available) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card flat outlined class="fill-height">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Bloqueado</v-list-item-title>
                <v-list-item-subtitle>{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(transactions.meta.amount_blocked) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
          <v-card flat outlined class="fill-height">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>A liberar</v-list-item-title>
                <v-list-item-subtitle>{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(transactions.meta.amount_unavailable) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </template>

      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field v-model="payload.amount" label="Valor do Saque*" type="number" prefix="R$:"
                                :error-messages="formErrors.amount"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
                <v-col cols="12" md="6">
                  <a-pix-search v-model="payload.pix_id" label="Pix*" type="text"
                                :error-messages="formErrors.pix_id"
                                hide-details="auto" :rules="[$rules.required]"/>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
            <v-btn depressed color="primary" @click="storeWithdrawal()" v-if="creating">Salvar</v-btn>
            <v-btn depressed color="primary" @click="updateWithdrawal()" v-if="updating">Alterar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import APixSearch from "@/components/APixSearch";

  export default {
    name: 'a-withdrawal-form',
    components: {APixSearch},
    data: () => ({
      formErrors: {},
      payload: {
        amount: '',
        pix_id: '',
        bank_account_id: '',
      },
      valid: true,
    }),
    created() {
      if (this.updating) {
        this.$store.dispatch('fetchWithdrawal', {
          business: this.business.id,
          withdrawal: this.$route.params.withdrawal
        }).then((response) => {
          this.payload = {
            amount: response.data.data.amount,
            pix_id: response.data.data.pix ? response.data.data.pix.id : '',
            bank_account_id: response.data.data.bank_account ? response.data.data.bank_account.id : '',
          }
        });
      }
      this.$store.dispatch('fetchTransactions', {
        business: this.business.id,
      });
    },
    methods: {
      storeWithdrawal() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('createWithdrawal', {
          business: this.business.id,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
      updateWithdrawal() {
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        this.$store.dispatch('updateWithdrawal', {
          business: this.business.id,
          withdrawal: this.$route.params.withdrawal,
          payload: this.payload
        }).then(() => {
          this.$refs.form.reset();
          this.$router.back();
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        });
      },
    },
    computed: {
      creating() {
        return this.$route.name === 'withdrawals_create';
      },
      updating() {
       return this.$route.name === 'withdrawals_update';
      },
      transactions() {
        return this.$store.getters.getTransactions;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
