<template>
  <v-row dense align-content="start" class="fill-height">
    <!--Transactions-->
<!--    <v-col cols="12">-->
<!--      <a-business-transactions-widget/>-->
<!--    </v-col>-->
    <!--Withdrawals-->
<!--    <v-col cols="12">-->
<!--      <a-business-withdrawals-widget/>-->
<!--    </v-col>-->
    <!--Map-->
    <v-col cols="12" sm="6">
      <a-business-map-widget/>
    </v-col>
    <!--Info-->
    <v-col cols="12" sm="6">
      <a-business-info-widget/>
    </v-col>
    <!--Ratings-->
    <v-col cols="12">
      <a-business-ratings-widget/>
    </v-col>
    <!--Periods-->
    <v-col cols="12" sm="6">
      <a-business-periods-widget/>
    </v-col>
    <!--Payment Methods-->
    <v-col cols="12" sm="6">
      <a-business-payment-methods-widget/>
    </v-col>
  </v-row>
</template>

<script>
import ABusinessMapWidget from "@/components/Dashboard/ABusinessMapWidget";
import ABusinessInfoWidget from "@/components/Dashboard/ABusinessInfoWidget";
import ABusinessPaymentMethodsWidget from "@/components/Dashboard/ABusinessPaymentMethodsWidget";
// import ABusinessTransactionsWidget from "@/components/Dashboard/ABusinessTransactionsWidget";
import ABusinessPeriodsWidget from "@/components/Dashboard/ABusinessPeriodsWidget";
// import ABusinessWithdrawalsWidget from "@/components/Dashboard/ABusinessWithdrawalsWidget";
import ABusinessRatingsWidget from "@/components/Dashboard/ABusinessRatingsWidget";

export default {
  name: 'a-dashboard',
  components: {
    ABusinessRatingsWidget,
    // ABusinessWithdrawalsWidget,
    ABusinessPeriodsWidget,
    // ABusinessTransactionsWidget,
    ABusinessPaymentMethodsWidget,
    ABusinessInfoWidget,
    ABusinessMapWidget
  }
};
</script>
