<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Transação</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn disabled icon @click="$router.push({ name: 'transactions_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="transactions">
      <v-col cols="12">
        <v-text-field v-model="transactionsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchTransactions()"/>
      </v-col>
      <v-col cols="12">
        <v-data-table
                    :headers="transactionsHeaders"
                    :items="transactions.data"
                    :page.sync="transactions.meta.current_page"
                    :items-per-page.sync="transactions.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:item.type="{ item }">
            <span :style="{ color: item.type === 'INBOUND' ? 'green' : 'red' }">{{ transactionType(item.type) }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn :to="{ name: 'transactions_update', params: { transaction: item.id } }" icon class="mr-0">
              <v-icon>mdi-file-document-edit-outline</v-icon>
            </v-btn>
            <v-btn @click="destroyTransaction(item)" icon class="mr-0">
              <v-icon>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="transactions.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-transaction-list',
    data: () => ({
      page: 1,
      transactionsSearch: '',
      transactionsHeaders: [
        { text: 'Tipo', value: 'type' },
        { text: 'Valor', value: 'amount' },
        { text: 'Data', value: 'created_at' },
        { text: 'Descrição', value: 'description' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toTransactionsPage', {
          business: this.business.id,
          search: this.transactionsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchTransactions();
    },
    methods: {
      fetchTransactions() {
        this.$store.dispatch('fetchTransactions', {
          business: this.business.id,
        });
      },
      searchTransactions() {
        this.$store.dispatch('searchTransactions', {
          business: this.business.id,
          search: this.transactionsSearch
        });
      },
      destroyTransaction(transaction) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteTransaction', {
            business: this.business.id,
            transaction: transaction.id
          }).then(() => {
            this.fetchTransactions();
          });
        }
      },
      transactionType(type) {
        switch (type) {
          case 'INBOUND':
            return 'Entrada';
          case 'OUTBOUND':
            return 'Retirada';
          default:
            return 'Indefinido';
        }
      },
    },
    computed: {
      transactions() {
        return this.$store.getters.getTransactions;
      },
      business() {
        return this.$store.getters.getLoginAuthBusiness;
      },
    },
  };
</script>
