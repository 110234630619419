import AAddressList from './index'
import AAddressForm from './form'

export default [
  {
    path: '/addresses',
    component: AAddressList,
    name: 'addresses',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addresses/create',
    component: AAddressForm,
    name: 'addresses_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/addresses/:address/update',
    component: AAddressForm,
    name: 'addresses_update',
    meta: {
      requiresAuth: true
    }
  },
];
