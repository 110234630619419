import resource from '../../resource';

const state = {
  withdrawals: null,
};

const getters = {
  getWithdrawals(state) {
    return state.withdrawals;
  },
};

const mutations = {
  SET_WITHDRAWALS(state, data) {
    state.withdrawals = data;
  },
};

const actions = {
  fetchWithdrawals(context, { business }) {
    return resource.get(`/businesses/${business}/withdrawals`)
      .then((response) => {
        context.commit('SET_WITHDRAWALS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchWithdrawal(context, { business, withdrawal }) {
    return resource.get(`/businesses/${business}/withdrawals/${withdrawal}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchWithdrawals(context, { business, search }) {
    return resource.get(`/businesses/${business}/withdrawals`, { params: { name: search } })
      .then((response) => {
        context.commit('SET_WITHDRAWALS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toWithdrawalsPage(context, { business, search, page }) {
    return resource.get(`/businesses/${business}/withdrawals`, { params: { name: search, page: page } })
      .then((response) => {
        context.commit('SET_WITHDRAWALS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createWithdrawal(context, { business, payload }) {
    return resource.post(`/businesses/${business}/withdrawals`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateWithdrawal(context, { business, withdrawal, payload }) {
    return resource.put(`/businesses/${business}/withdrawals/${withdrawal}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteWithdrawal(context, { business, withdrawal }) {
    return resource.delete(`/businesses/${business}/withdrawals/${withdrawal}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
