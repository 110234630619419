<template>
  <v-card flat outlined v-if="business">
    <v-list-item two-line>
      <v-dialog v-model="dialog" scrollable width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item-avatar size="80" rounded v-bind="attrs" v-on="on">
            <v-img :src="business.avatar ? business.avatar.url : require('../../assets/placeholder.png')">
              <div v-if="updating" class="d-flex justify-center align-center fill-height" style="background-color: rgba(0, 0, 0, .7); width: 100%;">
                <v-progress-circular color="white" size="56" width="1" indeterminate/>
              </div>
            </v-img>
          </v-list-item-avatar>
        </template>
        <v-card flat outlined>
          <v-card-title>
            <v-switch inset v-model="showControls" label="Gerenciar"/>
          </v-card-title>
          <v-card-text>
            <v-item-group v-model="payload.avatar_id" @change="updateProfile">
              <v-row dense>
                <v-col cols="4" md="3" v-for="avatar in avatars" :key="`avatar-${avatar.id}`">
                  <v-item v-slot="{ active, toggle }" :value="avatar.id">
                    <v-card flat @click="toggle">
                      <v-img :src="avatar.url" :aspect-ratio="1" contain>
                        <div v-if="showControls" class="d-flex justify-center align-center fill-height" style="background-color: rgba(0, 0, 0, .7); width: 100%;">
                          <v-btn dark fab depressed outlined @click.stop="destroyAvatar(avatar)">
                            <v-icon>mdi-trash-can-outline</v-icon>
                          </v-btn>
                        </div>
                      </v-img>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-item-group>
          </v-card-text>
          <v-card-actions>
            <v-btn depressed :loading="uploading" @click="$refs.input.click()">
              <v-icon left>mdi-upload</v-icon> Upload
            </v-btn>
            <v-spacer/>
            <v-btn depressed @click="dialog = false" color="primary">Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-list-item-content>
        <v-list-item-title>{{ business.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ business.email }}</v-list-item-subtitle>
        <input class="d-none" ref="input" @change="storeAvatar($event)" type="file"/>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import resource from "@/resource";

export default {
  name: 'a-profile-avatar-update',
  data: () => ({
    dialog: false,
    updating: false,
    uploading: false,
    showControls: false,
    payload: {
      avatar_id: '',
    },
    avatars: [],
  }),
  created() {
    this.fetchProfile();
    this.fetchAvatars();
  },
  methods: {
    fetchProfile() {
      resource.get(`businesses/${this.business.id}`)
        .then((response) => {
          this.payload = {
            avatar_id: response.data.data.avatar ? response.data.data.avatar.id : '',
          }
        });
    },
    updateProfile() {
      this.updating = true;
      this.dialog = false;
      resource.put(`/businesses/${this.business.id}`, this.payload, { preventDialog: true })
        .then((response) => {
          this.$store.dispatch('loginSetBusiness', response.data.data);
        }).finally(() => {
          this.updating = false;
        });
    },
    fetchAvatars() {
      resource.get(`businesses/${this.business.id}/avatars`, { params: { all: 1 } })
        .then((response) => {
          this.avatars = response.data.data;
        });
    },
    storeAvatar(event) {
      if (event.target.files.length) {
        this.uploading = true;
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        resource.post(`/businesses/${this.business.id}/avatars`, formData, {
          preventDialog: true,
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then(() => {
          this.fetchAvatars();
        }).finally(() => {
          this.uploading = false;
        });
      }
    },
    destroyAvatar(avatar) {
      if (confirm('Tem certeza que deseja excluir esse item?')) {
        return resource.delete(`/businesses/${this.business.id}/avatars/${avatar.id}`)
          .then(() => {
            this.fetchAvatars();
          });
      }
    },
  },
  computed: {
    business() {
      return this.$store.getters.getLoginAuthBusiness;
    },
  }
};
</script>
