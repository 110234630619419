import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import resource from './resource';
import vuetify from './plugins/vuetify';

import './registerServiceWorker';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import './svg-font/dashicons/icons.css';

Vue.config.productionTip = false;
Vue.prototype.$axios = resource;

Vue.prototype.$rules = {
  required: value => !(value === '' || value === null || value === undefined) || 'Campo obrigatório',
  files_required: value => !(value.length === 0) || 'Selecione um arquivo',
  check: value => (value === true) || 'Campo deve ser aceito',
  password: value => (value.length >= 6) || 'Senha deve ter no mínimo 6 digitos',
  email: value => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || 'e-mail inválido',
  full_name: value => /^([\wÀ-ú]{3,})+\s+([\wÀ-ú\s]{3,})+$/i.test(value) || 'Nome inválido',
  card_number: value => (value.length >= 14 && value.length <= 16) || 'O número do cartão deve conter entre 14 e 16 digitos',
  card_exp_month: value => (value.length === 2 && (value >= 1 && value <= 12)) || 'O mês do cartão deve conter 2 digitos e válido',
  card_exp_year: value => (value.length === 4) || 'O ano do cartão deve conter 4 digitos',
  card_cvc: value => (value.length === 3 || value.length === 4) || 'O cvc do cartão deve conter entre 3 e 4 digitos',
  telephone: value => (value.length === 10 || value.length === 11) || 'O número de telefone deve conter entre 10 e 11 digitos',
  cep: value => (value.length === 8) || 'O CEP deve conter 8 digitos',
  cpf: value => (value.length === 11) || 'O CPF deve conter 11 digitos',
  cnpj: value => (value.length === 14) || 'O CNPJ deve conter 14 digitos',
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
