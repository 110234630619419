import resource from "../../resource";

const state = {
  paymentMethods: null,
  transactions: null,
  periods: null,
  withdrawals: null,
  ratings: null
};

const getters = {
  getBusinessPaymentMethods(state) {
    return state.paymentMethods;
  },
  getBusinessTransactions(state) {
    return state.transactions;
  },
  getBusinessPeriods(state) {
    return state.periods;
  },
  getBusinessWithdrawals(state) {
    return state.withdrawals;
  },
  getBusinessRatings(state) {
    return state.ratings;
  }
};

const mutations = {
  SET_BUSINESS_PAYMENT_METHODS(state, data) {
    state.paymentMethods = data;
  },
  SET_BUSINESS_TRANSACTIONS(state, data) {
    state.transactions = data;
  },
  SET_BUSINESS_PERIODS(state, data) {
    state.periods = data;
  },
  SET_BUSINESS_WITHDRAWALS(state, data) {
    state.withdrawals = data;
  },
  SET_BUSINESS_RATINGS(state, data) {
    state.ratings = data;
  }
};

const actions = {
  fetchBusinessPaymentMethods(context, { business }) {
    return resource.get(`/businesses/${business}/payment_methods`, { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_BUSINESS_PAYMENT_METHODS', response.data);
        return Promise.resolve(response);
      }).catch(function (error) {
        return Promise.reject(error);
      });
  },
  fetchBusinessTransactions(context, { business }) {
    return resource.get(`/businesses/${business}/transactions`, { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_BUSINESS_TRANSACTIONS', response.data);
        return Promise.resolve(response);
      }).catch(function (error) {
        return Promise.reject(error);
      });
  },
  fetchBusinessPeriods(context, { business }) {
    return resource.get(`/businesses/${business}/periods`, { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_BUSINESS_PERIODS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchBusinessWithdrawals(context, { business }) {
    return resource.get(`/businesses/${business}/withdrawals`, { params: { all: 1 } })
      .then((response) => {
        context.commit('SET_BUSINESS_WITHDRAWALS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchBusinessRatings(context, { business }) {
    return resource.get(`/businesses/${business}/ratings`)
      .then((response) => {
        context.commit('SET_BUSINESS_RATINGS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
